html,
body {
    height: 100%;
}

body {
    /*display: -ms-flexbox;*/
    /*display: flex;*/
}

.pform-border-right {
   border-right: 1px solid rgba(0,0,0,.1);
}

@media only screen and (max-width: 767px) {
    .pform-border-right {
        border-right: none;
    }
}

.pform {
    padding: 2rem 2rem;
    background: #fafafa;
}

.div-hidden {
    display: none;
}

.nittro-transition-bar::before {
    /*background: #4A90E2;*/
    background: #007bff;
}

div.nittro-transition-bar {
    width: 100%;
    height: 4px;
}

.a-sm {
    font-size: 0.7rem;
}

.btn-stretch {
    height:100%;
}

/* let's also make nittro flashes look like bootstrap alerts */

.nittro-flash {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.nittro-flash.nittro-flash-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.nittro-flash.nittro-flash-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.nittro-flash.nittro-flash-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.nittro-flash.nittro-flash-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
